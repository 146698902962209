<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">校稿管理</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit">
          <template #modal="{ row }">
            <vxe-form ref="proofForm" v-bind="proofFormOptions.options" :data="row">
              <template #column-programs="{ data }">
                <select-box v-bind="programSelectorOptions" v-model="data.ProgramIds" />
              </template>
              <template #column-report-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.ReportUserId" />
              </template>
              <template #column-report-files>
                <FileUploadTable ref="proofFormReportFileGrid" v-bind="proofFormOptions.reportFileUploadOptions" />
              </template>
              <template #column-check-list="{ data }">
                <table class="w-full table border" v-if="data.ProofChecklist">
                  <thead>
                    <tr class="bg-gray-50 border-b">
                      <th class="p-2 border-r text-sm font-thin" style="width: 150px">
                        <div class="flex items-center justify-center">類型</div>
                      </th>
                      <th class="p-2 border-r text-sm font-thin" style="width: 300px">
                        <div class="flex items-center justify-center">檢核項目</div>
                      </th>
                      <th class="p-2 border-r text-sm font-thin">
                        <div class="flex items-center justify-center">校稿建議</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b text-sm">
                      <td class="p-2 border-r">
                        <vxe-checkbox v-model="data.ProofChecklist.IsTemplate" :checked-value="true"
                          :unchecked-value="false"></vxe-checkbox>
                        版底
                      </td>
                      <td class="p-2 border-r">
                        <div>● 封面，字型，字體大小。</div>
                        <div>● 標題層次，內文段落。</div>
                        <div>● 圖、圖名稱、圖資料來源。</div>
                        <div>● 表、表名稱、表資料來源。</div>
                      </td>
                      <td>
                        <vxe-textarea v-model="data.ProofChecklist.IsTemplateText"
                          :autosize="{ minRows: 4, maxRows: 6 }"></vxe-textarea>
                      </td>
                    </tr>
                    <tr class="border-b text-sm">
                      <td class="p-2 border-r">
                        <vxe-checkbox v-model="data.ProofChecklist.IsAbstract" :checked-value="true"
                          :unchecked-value="false"></vxe-checkbox>
                        評析摘要
                      </td>
                      <td class="p-2 border-r">● 摘要為研究目的及發現，非緣起及前言。</td>
                      <td>
                        <vxe-textarea v-model="data.ProofChecklist.IsAbstractText"
                          :autosize="{ minRows: 4, maxRows: 6 }"></vxe-textarea>
                      </td>
                    </tr>
                    <tr class="border-b text-sm">
                      <td class="p-2 border-r">
                        <vxe-checkbox v-model="data.ProofChecklist.IsContent" :checked-value="true"
                          :unchecked-value="false"></vxe-checkbox>
                        評析內容
                      </td>
                      <td class="p-2 border-r">
                        <div>● 評析語意流暢度及邏輯性。</div>
                        <div>● 錯別字檢視校正。</div>
                      </td>
                      <td>
                        <vxe-textarea v-model="data.ProofChecklist.IsContentText"
                          :autosize="{ minRows: 4, maxRows: 6 }"></vxe-textarea>
                      </td>
                    </tr>
                    <tr class="border-b text-sm">
                      <td class="p-2 border-r">
                        <vxe-checkbox v-model="data.ProofChecklist.IsChart" :checked-value="true"
                          :unchecked-value="false"></vxe-checkbox>
                        圖表內容
                      </td>
                      <td class="p-2 border-r">
                        <div>● 圖表內容是否清晰。</div>
                        <div>● 基本單位符合國際慣用上下標。</div>
                      </td>
                      <td>
                        <vxe-textarea v-model="data.ProofChecklist.IsChartText" :autosize="{ minRows: 4, maxRows: 6 }">
                        </vxe-textarea>
                      </td>
                    </tr>
                    <tr class="border-b text-sm">
                      <td class="p-2 border-r">
                        <vxe-checkbox v-model="data.ProofChecklist.IsWord" :checked-value="true"
                          :unchecked-value="false"></vxe-checkbox>
                        字數是否符合
                      </td>
                      <td class="p-2 border-r">● 字數是否達2,000字+1圖/表（Word檔）。</td>
                      <td>
                        <vxe-textarea v-model="data.ProofChecklist.IsWordText" :autosize="{ minRows: 4, maxRows: 6 }">
                        </vxe-textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template #column-comment-files>
                <FileUploadTable ref="proofFormFileGrid" v-bind="proofFormOptions.uploadOptions" />
              </template>
              <template #column-document-records="{ data }">
                <RecordTable :document-id="data.Id" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <!-- <vxe-button type="button" status="primary" content="批改" @click="proofModal.show(grid.editingRow)">
            </vxe-button> -->
            <vxe-button type="button" status="primary" content="送審"
              :disabled="!grid.editingRow || !grid.editingRow.ProofChecklist.IsTemplate || !grid.editingRow.ProofChecklist.IsAbstract || !grid.editingRow.ProofChecklist.IsContent || !grid.editingRow.ProofChecklist.IsChart || !grid.editingRow.ProofChecklist.IsWord || isDisabled"
              @click="() => { isDisabled = true; proofFormOptions.approve(); }"></vxe-button>
            <vxe-button type="button" status="danger" content="退回" :disabled="isDisabled" @click="() => { isDisabled = true; proofFormOptions.reject(); }"></vxe-button>
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :readonly="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <div class="flex justify-center">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="查看" @click.stop="() => { isDisabled = false; grid.edit(row); }">
                <FontAwesome icon="eye" class="w-4 h-4" />
              </button>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import RecordTable from "@/components/document/RecordTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    RecordTable
  },
  setup() {
    const model = CloudFun.current?.model
    const user = CloudFun.current?.user;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    const isDisabled = ref(false);

    const gridOptions: GridOptions = {
      stripe: false,
      title: '校稿管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [{
        field: 'IndustryCategoryName',
        title: '產業類別',
        width: '10%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '30%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ProofDate',
        title: '校稿截止日期',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("ProofUserId", Operator.Equal, user?.Id)
            .and(
              new Condition("Status", Operator.Equal, enums.DocumentStatus.ProofRead.Value)
            ).and(params.condition!);
          return model.dispatch('document/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        // save: model ? (params) => model.dispatch('document/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true }
    }

    const proofForm = ref<any>();
    const proofFormReportFileGrid = ref<any>()
    const proofFormFileGrid = ref<any>();
    const proofFormOptions = {
      options: {
        titleWidth: 80,
        titleAlign: 'right',
        items: [
          {
            field: 'Title',
            title: '標題',
            span: 12,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', readonly: true } }
          },
          {
            field: 'PublishedDate',
            title: '出版日期',
            span: 12,
            titleWidth: 80,
            itemRender: {
              name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: true }
            }
          },
          {
            field: 'TopicTypeName',
            title: '主題',
            span: 12,
            itemRender: { name: '$input', props: { readonly: true } }
            // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
          },
          {
            field: 'ReportUserId',
            title: '負責人',
            span: 12,
            slots: { default: "column-report-user-id" }
          },
          // {
          //   title: '產業類別',
          //   span: 24,
          //   slots: { default: "column-programs" }
          // },
          {
            span: 24,
            slots: { default: "column-report-files" }
          },
          {
            title: "檢核項目",
            span: 24,
          },
          {
            span: 24,
            slots: { default: "column-check-list" }
          },
          {
            field: 'Comment',
            title: '意見',
            span: 24,
            itemRender: { name: '$textarea', props: { placeholder: '請輸入意見' } }
          },
          {
            span: 24,
            slots: { default: "column-comment-files" }
          },
          {
            span: 24,
            slots: { default: "column-document-records" }
          },
        ]
      } as VxeFormProps,
      uploadOptions: {
        title: "上傳校稿附件",
        promises: {
          query() {
            return model?.dispatch('files/query', {
              condition: new Condition("DocumentRecordId", Operator.IsNull)
                .and("Type", Operator.Equal, 3).and("DocumentId", Operator.Equal, documentId.value)
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', fileType: 3, entityId: 'Document_' + documentId.value });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      reportFileUploadOptions: {
        title: "上傳研究報告",
        canDelete: false,
        canUpload: false,
        promises: {
          query() {
            return grid.value.editingRow
              ? new Promise<any>(resolve => {
                // 最後一筆撰稿人record的檔案
                const availableStatuses = [enums.DocumentStatus.Writing.Value, enums.DocumentStatus.ChangedReturn.Value, enums.DocumentStatus.Changed.Value, enums.DocumentStatus.ReviewedReturn.Value, enums.DocumentStatus.ProofReadReturn.Value];
                model!.dispatch("documentRecord/query", {
                  condition: new Condition("DocumentId", Operator.Equal, grid.value.editingRow.Id)
                    .and("BeforeStatus", Operator.In, availableStatuses),
                  sortings: new Sorting('CreatedTime', SortOrder.Descending),
                  page: 1,
                  pageSize: 1
                }).then(async resp => {
                  const recordId = resp.data[0].Id
                  const files = await model?.dispatch('files/query', {
                    condition: new Condition("DocumentRecordId", Operator.Equal, recordId)
                      .and(
                        new Condition("Type", Operator.Equal, 3)
                          .or("Type", Operator.Equal, 4)
                      )
                  });
                  resolve(files);
                });
              })
              : undefined;
            // return grid.value.editingRow
            //   ? model?.dispatch('files/query', {
            //     condition: new Condition("DocumentId", Operator.Equal, grid.value.editingRow.Id)
            //       .and("Type", Operator.Equal, 5)
            //   })
            //   : undefined;
          }
        },
      },
      approve() {
        grid.value.editingRow.FileIds = proofFormFileGrid.value.getData().map((e: any) => e.Id);
        model!.dispatch("document/review", grid.value.editingRow).then(() => {
          grid.value.refresh();
          grid.value.isModalPopup = false;
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }));
      },
      reject() {
        grid.value.editingRow.FileIds = proofFormFileGrid.value.getData().map((e: any) => e.Id);
        model!.dispatch("document/rejectProof", grid.value.editingRow).then(() => {
          grid.value.refresh();
          grid.value.isModalPopup = false;
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      }
    };

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      readonly: true,
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true)
            .and("ParentId", Operator.IsNotNull)
            .and(params.condition!);
          return model!.dispatch("program/query", params) // eslint-disable-line
        }
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      readonly: true,
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const documentId = ref<any>();

    return {
      grid,
      gridOptions,
      proofForm,
      proofFormReportFileGrid,
      proofFormFileGrid,
      proofFormOptions,
      model,
      programSelectorOptions,
      userSelectorOptions,
      documentId,
      isDisabled,
    }
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('document/find', row.Id) : undefined;
      Object.assign(row, entity);
      row.TopicTypeName = (Object.values(this.$model.enums.TopicType) as any[]).find(e => e.Value === row.TopicType).Name;
      row.ProofChecklist = {
        IsTemplate: false,
        IsAbstract: false,
        IsContent: false,
        IsChart: false,
        IsWord: false
      };
      if (row.Id) this.documentId = row.Id
      if (this.proofFormReportFileGrid) this.proofFormReportFileGrid.refresh();

      callback()
    }
  }
})
</script>
